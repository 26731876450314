import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { MainService } from 'src/app/app.service';

@Injectable({
  providedIn: 'root'
})
export class PostgresService {

  constructor(private _http: HttpClient, private mainService: MainService) { }

  generateCode (dbaccess_number, cpus_number, ram_number, postgres_version, protheus_version): Observable<any> {
    return this._http.post('/api/psqltune' , JSON.stringify({ dbaccess_number, cpus_number, ram_number, postgres_version, protheus_version }), this.mainService.httpOptions)
        .pipe(
            map(res => {
                return res;
        }),
            catchError(this.mainService.handleError)
        );
}

//   {
//     "dbaccess_number": "100",
//     "cpus_number": "18",
//     "ram_number": "64",
//     "postgres_version": "15",
//     "protheus_version": "1212410"
// }
}
