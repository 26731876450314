import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';


@Injectable()

export class MainService {

    //public url_api = environment.url_v1;
    public httpOptions = {
        headers : new HttpHeaders ({
            'Content-type': 'application/json',
            'Accept': '*/*'
            })
        };
    

    constructor( private _http: HttpClient ) {
    }

    handleError( error: HttpErrorResponse ) {
        if (error instanceof ErrorEvent) {
            console.error('Erro ocorrido: ', error);
        } else {
            console.error( 'O servidor te ignorou e respondeu ' + error.status + ' e ainda disse: ' + error.message  );            
        }
        return throwError(() => new Error('I have a bad feeling about this...' + error.status + ' and that ' + error.message)) 
    }
    
    logOut() {
        location.reload();
    }
}