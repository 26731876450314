import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { FormsModule } from '@angular/forms';
import { PostgresComponent } from './postgres.component';
import { PostgresService } from './postgres.service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PoCodeEditorModule } from '@po-ui/ng-code-editor';
import { TableDetailComponent } from './table-detail/table-detail.component';

@NgModule({
  declarations: [
   PostgresComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
    FormsModule,
    PoModule,
    PoTemplatesModule,
    AppRoutingModule,
    HttpClientModule,
    TableDetailComponent,
    PoCodeEditorModule   
  ],
  providers: [
    PostgresService
  ]
})
export class PostgresModule { }