  <div class="po-wrapper">

    <po-toolbar 
      p-title="TuneDB - Protheus"
      [p-profile-actions]="profileActions"
      p-profile-icon="po-icon-user"
      >
    </po-toolbar>
  
    <po-menu
      [p-menus]="menu"
      p-logo="../assets/images/tuneDB-front.png"
      p-short-logo="../favicon.ico">
    </po-menu>
  
    <router-outlet></router-outlet>
  </div>
