import { Component, Input } from '@angular/core';
import { PoDividerComponent, PoDividerModule, PoTableColumn, PoTableModule } from '@po-ui/ng-components';

@Component({
  selector: 'app-table-detail',
  standalone: true,
  imports: [PoTableModule, PoDividerModule ],
  templateUrl: './table-detail.component.html',
  styleUrl: './table-detail.component.css'
})
export class TableDetailComponent {

  @Input() parameter_title: String = '';
  @Input() parameterColumns: Array<PoTableColumn> = [{}];
  @Input() parameterItem: Array<any> = [{}];

}
