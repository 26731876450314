<po-page-default p-title="Postgres">
    <po-container>
        <div class="texto_base_column">
            <p class="po-font-text-large-bold"> A configuração do PostgreSQL tem como premissa os seguintes itens:  </p>
            <table class="margin_table">
                <tr>
                    <td>
                        <p class="po-font-text-large">  - Sistema Operacional: Linux </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p class="po-font-text-large">  - Tipo de Disco: SSD </p>
                    </td>
                </tr>
                <tr> 
                    <td>
                        <p class="po-font-text-large">  - <a href="https://tdn.totvs.com/pages/releaseview.action?pageId=563437610" target="_blank">Hardware Mínimo Recomendado</a> </p> 
                    </td>       
                </tr>   
            </table>     
        </div>
    
        <po-divider></po-divider>

        <div class="sample-container" *ngIf="loading">
            <po-loading-overlay></po-loading-overlay>
        </div>
    
        <div class="texto_base_column">
            <p class="po-font-subtitle">Configurações: </p>
            <br>
            <div class="texto_base_row">
                <po-number
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                    p-label="Conexões no DBAccess"
                    name="dbaccess_number"
                    p-required="true"
                    [(ngModel)]="dbaccess_number" >
                </po-number>
                <po-number
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                    p-label="CPUs disponíveis"
                    name="cpus_number"
                    p-required="true"
                    [(ngModel)]="cpus_number" >
                </po-number>
                <po-number
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                    p-label="Memória RAM (GB)"
                    name="ram_number"
                    p-required="true"
                    [(ngModel)]="ram_number" >
                </po-number>
                <po-select
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                    name="postgresVersion"
                    [(ngModel)]="postgres_version"
                    p-label="Versão do Database"
                    [p-options]="postgres_vOptions"
                    >
                </po-select>
                <po-select
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                    name="protheusVersion"
                    [(ngModel)]="protheus_version"
                    p-label="Versão do Protheus"
                    [p-options]="protheus_options"
                    >
                </po-select>
                <po-button
                    class="po-sm-md-lg-xl-4 options_min_size position_align_end button_generate"
                    (p-click)="generateCode()"
                    p-icon="po-icon-settings"
                    p-label="Gerar">

                </po-button>
                
            </div>
            <p><strong>OBS:</strong> A quantidade de memória disponível pode ser calculada pegando o valor de memória TOTAL do servidor, deixando 2 GB para o Sistema Operacional.</p>
            <p><strong>Por exemplo:</strong> servidor com 16GB - 2 GB, memória disponível para o PostgreSQL 14GB.<p>
        </div>
    </po-container>

    <po-container>
        <po-tabs>
            <po-tab p-active p-label="Parametros do Profile">
                <app-table-detail *ngFor="let item of parameter_Items" [parameter_title]="item.title" [parameterColumns]="parameterColumns" [parameterItem]="item.objects" ></app-table-detail>
            </po-tab>
            <po-tab p-label="Exportar Configuração">
                <div class="texto_base_column">
                    <div class="texto_base_row_align_start">
                        <po-select
                            class="po-sm-md-lg-xl-4 options_min_size"
                            name="postgresVersion"
                            [(ngModel)]="export_format"
                            p-label="Versão do Database"
                            [p-options]="export_options"
                            (p-change)="onchangeLanguage()"
                            >
                        </po-select> 
                        <p *ngIf="show_copy" class="po-font-text-large-bold text_color_approved position_align_end"> Texto copiado! </p>
                        <po-button
                            class="po-sm-md-lg-xl-4 options_min_size position_align_end"
                            (p-click)="copyToClipboard()"
                            p-icon="po-icon-copy"
                            p-label="Copiar p/ Área de Transferência">

                        </po-button>
                    </div>
                    <po-code-editor
                        class="options_min_height"
                        p-height="500px"
                        [(ngModel)]="code_to_view"
                        [p-language]="export_format"
                        p-readonly="true"
                        p-theme="vs" >
                    </po-code-editor>
                </div>
                

            </po-tab>
        </po-tabs>
    </po-container>
    
</po-page-default>
