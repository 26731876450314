import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PoNotificationService, PoToolbarAction, PoMenuItem, PoToolbarModule } from '@po-ui/ng-components';
import { MainService } from '../app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent {

  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-exit', label: 'Sair', type: 'danger', separator: true, action: this.mainservice.logOut.bind(this) }
  ];

  menu: Array<PoMenuItem> = [
    { label: 'Postgres', icon: 'po-icon-database', shortLabel: 'Postgres', link: "postgres" }
  ];

  constructor(
    private router: Router,
    public mainservice: MainService,
    public poNotification: PoNotificationService) { 
      
    }


}
