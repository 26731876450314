import { Component } from '@angular/core';
import { PoTableDetail } from '@po-ui/ng-components';
import { MainService } from 'src/app/app.service';
import { PostgresService } from './postgres.service';
import { Subject, takeUntil } from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
  selector: 'app-postgres',
  templateUrl: './postgres.component.html',
  styleUrl: './postgres.component.css'
})
export class PostgresComponent {

  unsubscribe$ = new Subject<void>();
  loading = false;
  show_copy = false;
  
  code_to_view: string = ''
  dbaccess_number: Number = 100;
  cpus_number: Number = 8;
  ram_number: number = 42;
  postgres_vOptions: Array<any> = [
    { label: '16', value: '16' },
    { label: '15', value: '15' },
    { label: '14', value: '14' },
    { label: '13', value: '13' },
    { label: '12', value: '12' },
    { label: '11', value: '11' },
  ]
  postgres_version: String = '';
  protheus_options: Array<any> = [
    { label: '12.2110', value: '2110' },
    { label: '12.2210', value: '2210' },
    { label: '12.2310', value: '2310' },
    { label: '12.2410', value: '2410' }
  ]
  protheus_version: String = ''

  parameterDetail: PoTableDetail = {
    columns: [
      { property: 'Detalhes' },
    ],
    typeHeader: 'top'
  };

  parameterColumns = [
    { property: 'parameter', label: 'Parâmetro', type: 'string', width: '30%' },
    { property: 'default_value', label: 'Padrão', type: 'string' },
    { property: 'oltp', label: 'OLTP', type: 'string' }
  ]

  parameter_Items: Array<any> = [];

  export_format: String = '';
  export_options: Array<any> = [
    { label: 'UNIX-like config file', value: 'shell' },
    { label: 'ALTER SYSTEM commands', value: 'sql'}
  ]

  constructor(
    public mainService: MainService, 
    public postgresService: PostgresService,
    public clipboard: Clipboard) { }

  onchangeLanguage(){
    if (this.export_format === "shell"){
      this.code_to_view = this.convertObjectToShell()
    }

    if (this.export_format === "sql") {
      this.code_to_view = this.convertObjectToSQL();
    }
  }

  convertObjectToShell() {
    let codeConverted: string = '';
    this.parameter_Items.forEach( parameter => {
      codeConverted += '# ' + parameter.title + '\n';
      parameter.objects.forEach( object => {
        codeConverted += object.parameter + ' = ' + object.oltp + '\n'
      });
      codeConverted += '\n'
    })
    return codeConverted
  }

  convertObjectToSQL() {
    let codeConverted: string = '';
    this.parameter_Items.forEach( parameter => {
      codeConverted += '-- ' + parameter.title + '\n';
      parameter.objects.forEach( object => {
        codeConverted += 'ALTER SYSTEM SET ' + object.parameter + ' TO ' + "'" + object.oltp + "';" + '\n'
      });
      codeConverted += '\n'
    })
    return codeConverted
  }

  copyToClipboard(){
    this.loading = true;
    const pending = this.clipboard.beginCopy(this.code_to_view);
    let remainingAttempts = 5;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.loading = false;
        this.show_copy = true;
        setTimeout(() => {this.show_copy = false}, 1500)
        pending.destroy();
      }
    };
    attempt();
  }

  generateCode(){
    this.loading = true;
    this.postgresService.generateCode(this.dbaccess_number, this.cpus_number, this.ram_number, this.postgres_version, this.protheus_version)
    .pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: ((response) => {
        this.parameter_Items = response
        this.loading = false;
      }),
      error: ((msgError) => {
        this.mainService.handleError(msgError);
        this.loading = false;
      })
     });;

  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
