<br>
<p class="po-font-subtitle">{{parameter_title}} </p>
<po-table
    p-sort="true"
    [p-columns]="parameterColumns"
    [p-items]="parameterItem">

    <ng-template
        p-table-row-template
        let-rowItem
        let-i="rowIndex"
        [p-table-row-template-arrow-direction]="'left'"
    >
    <p> {{ rowItem.texto_traduzido }}  </p>
    </ng-template>
</po-table>
<po-divider></po-divider>
