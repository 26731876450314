import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostgresComponent } from './menu/postgres/postgres.component';
import { MenuComponent } from './menu/menu.component';

const routes: Routes = [
  { path: 'menu', component: MenuComponent, 
        children: [
          { 
            path: 'postgres', 
            component: PostgresComponent
          }
        ] },
  // { path: 'loginError', component: LoginErrorComponent },
  { path: '', pathMatch: 'full', redirectTo: '/menu/postgres',}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }